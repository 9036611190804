import React from 'react';
import { Button, Typography, Grid, Stack } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createCabVendorWatcher, createVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
// import BlackButton from '../../../overrides/blackButton'
import CloseButton from '../../../overrides/closeButton';

function VendorCreation(props) {
    const dispatch = useDispatch()

    const { setOpen, status } = props;

    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const VendorSchema = Yup.object().shape({
        vendorName: Yup.string('Enter vendor name').min(2, 'Too Short!').max(50, 'Too Long!').required('Vendor Name is required'),
        address: Yup.string('Enter vendor address').required('Vendor address is required'),
        description: Yup.string('Enter vendor description').required('Vendor description is required'),
        email: Yup.string('Enter your Email Id').email('Email must be a valid email address').required('Email Id is required'),
        email2: Yup.string('Enter your Email Id').email('Email must be a valid email address'),
        email3: Yup.string('Enter your Email Id').email('Email must be a valid email address'),
        email4: Yup.string('Enter your Email Id').email('Email must be a valid email address'),
        email5: Yup.string('Enter your Email Id').email('Email must be a valid email address'),
        hrEmail: Yup.string('Enter your Email Id').email('Email must be a valid email address').required('Email Id is required'),
    });

    const formik = useFormik({
        initialValues: {
            vendorName: '',
            description: '',
            address: '',
            email: '',
            email2: '',
            email3: '',
            email4: '',
            email5: '',
            hrEmail: ''
        },

        validationSchema: VendorSchema,

        onSubmit: () => {
            const { description, vendorName, email, address, email2, email3, email4, email5, hrEmail } = formik.values
            let data = {
                "ACTION": "INSERT",
                vendorName,
                address,
                email,
                description,
                email2,
                email3,
                email4,
                email5,
                hrEmail
            }
            dispatch(createCabVendorWatcher(data, setOpen))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit Vendor' : 'Vendor Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >
                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Name'
                                label='Name'
                                name='vendorName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("vendorName")}
                                error={Boolean(touched.vendorName && errors.vendorName)}
                                helperText={touched.vendorName && errors.vendorName}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email'
                                label='Email'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email")}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='HR Email'
                                label='HR Email'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("hrEmail")}
                                error={Boolean(touched.hrEmail && errors.hrEmail)}
                                helperText={touched.hrEmail && errors.hrEmail}
                            />
                        </Grid>
                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Description'
                                label='Description'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("description")}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Address'
                                label='Address'
                                name='vendorAddress'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("address")}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email 2'
                                label='Email 2'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email2")}
                                error={Boolean(touched.email2 && errors.email2)}
                                helperText={touched.email2 && errors.email2}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email 3'
                                label='Email 3'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email3")}
                                error={Boolean(touched.email3 && errors.email3)}
                                helperText={touched.email3 && errors.email3}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email 4'
                                label='Email 4'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email4")}
                                error={Boolean(touched.email4 && errors.email4)}
                                helperText={touched.email4 && errors.email4}
                            />
                        </Grid>

                        <Grid
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email 5'
                                label='Email 5'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email5")}
                                error={Boolean(touched.email5 && errors.email5)}
                                helperText={touched.email5 && errors.email5}
                            />
                        </Grid>
                        
                        <Grid
                            xs={12}
                            item={true}
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                            <CloseButton onClick={() => handleClose()}
                                sx={{
                                    //width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    minWidth: '120px',
                                    marginTop: 'auto',
                                }}
                                variant='contained'
                                size='medium'
                                type='button'>
                                <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                            </CloseButton>
                            <Button onClick={handleSubmit}
                                style={{
                                    //width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    minWidth: '120px',
                                    backgroundColor: 'common.Bblack',
                                    marginTop: 'auto'
                                }}
                                variant='contained'
                                size='medium'
                                type='submit'
                            >
                                <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                            </Button>
                            </Stack>
                            
                        </Grid>
                    </Grid>

                </Form>
            </FormikProvider>
        </>
    );

}

export default VendorCreation;