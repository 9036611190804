import { cabList } from "../../actionCreators/cab/index";
import { call, put, takeEvery } from "redux-saga/effects";
import CabService from "../../../services/cab/index";
import { GET_CAB_BOOKING_WATCHER, CREATE_CAB_BOOKING_WATCHER } from "../../actionTypes/cab";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getCabEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CabService.get, action.payload);
        yield put(cabList(data.recordset));
        yield put(updateFalseLoader());
        //yield put(updateAlert('Success', 'success'));
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* createCabEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CabService.create, action.payload);
        // yield put(cabList(data.data.recordset));
        action.navigate('/cab/bookingHistory')
        yield put(updateFalseLoader());
        yield put(updateAlert('Created Successfully', 'success'));
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}


//saga 
export const CabSaga = [
    takeEvery(GET_CAB_BOOKING_WATCHER, getCabEffect),
    takeEvery(CREATE_CAB_BOOKING_WATCHER, createCabEffect),
]


