import { CAB_LIST, CREATE_CAB_BOOKING_WATCHER, GET_CAB_BOOKING_WATCHER } from "../../actionTypes/cab";

// Worker triggering actionCreators

export function getCabBookingWatcher(data) {
    return { type: GET_CAB_BOOKING_WATCHER, payload: data };
}

export function createCabBookingWatcher(data, navigate) {
    return { type: CREATE_CAB_BOOKING_WATCHER, payload: data, navigate };
}

// Redux state changing actionCreators
export function cabList(data) {
    return { type: CAB_LIST, payload: data };
}
