import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import { useSelector } from 'react-redux';
// import { getCabBookingWatcher } from '../../../redux/actionCreators/cab';
import BookingHistoryRable from '../../../components/user/cabBooking/table';
import BookingHistoryHeader from '../../../components/user/cabBooking/header';
import { TABLE_HEAD } from '../../../components/user/cabBooking/table';
import { SUB_TABLE_HEAD } from '../../../components/user/cabBooking/cabBookingChildTable';

export default function CabBooking() {

    // const dispatch = useDispatch()

    const { CabReducers: { CabList } } = useSelector((state) => state)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = CabList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
    }

    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Booking History"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Booking History </Typography>
                </Box>
                <Box p='10px 0'>
                    <BookingHistoryHeader handleSearch={handleSearch} searchText={searchText} rowData={CabList} columnData={[...TABLE_HEAD,...SUB_TABLE_HEAD]} a />
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "0 0 0px 0px", padding: '20px' }}>
                    <BookingHistoryRable searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}
