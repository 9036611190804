import http from "../../http-common";

class EmployeeManagementService {

    getAll(data) {
        return http.post("/admin/getEmployeeManagement", data);
    }
    getRoleNames() {
        return http.post("/admin/dropDown");
    }
    create(data) {
        return http.post("/admin/addEmployeeManagement", data);
    }
    update(id, data) {
        return http.put(`/admin/employeeManagement/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/employeeManagement/${id}`, data);
    }
    createBulkEmployee(data) {
        return http.post("/admin/bulkUserInsert", data);
    }
    getListById(data) {
        return http.post("/admin/getListById", data);
    }

}

export default new EmployeeManagementService();