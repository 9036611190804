import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Collapse,
    Card
} from '@mui/material';
import CabBookingChildTable from './cabBookingChildTable';
import ActionButtons from '../../admin/dashboard/actionButtons';

const BookingHisTableRow = ({ rowData, index,handleRequestSort, order,orderBy }) => {

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.EmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.OrderDate.slice(0, 10)}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.Department}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.MobileNo}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.TypeOfCab}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.CabVendor}
                    </Typography>
                </TableCell>

                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.CostCentre}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.DateOfTravelOn.slice(0, 10)}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.DateOfTravelOut.slice(0, 10)}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.EmailID}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData["Flight/TrainNumber"]}
                    </Typography>
                </TableCell>
                {/* <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.Gender}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.HREmailID}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.ReportTimeTo}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.ReportingAddress}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.ReportingTimeFrom}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.SpecialInstructions}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.SupervisiorEmailID}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.TravelDestinationFrom}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.TravelDestinationTo}
                    </Typography>
                </TableCell>
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.TypeOfTrip}
                    </Typography>
                </TableCell> 
                <TableCell align="left" >
                    <Typography variant='t14_600'>
                        {rowData.UsageType}
                    </Typography>
                </TableCell> */}
                <TableCell align="right" sx={{ width: '100px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'cabBooking'}/>
                </TableCell>
                
            </TableRow>

            <TableRow>
                <TableCell colSpan={12} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <CabBookingChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} status={status} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default BookingHisTableRow