import { DraftsOutlined, PhoneInTalkOutlined } from '@mui/icons-material'
import { Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import Breadcrumbs from '../../../components/breadcrumbs'

export default function ContactPage() {
    return (
        <div>
            <Box p={{sm:'30px', xs: '20px'}}>
                <Breadcrumbs content={["Contact Us"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Contact Us </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                        <Grid item lg={6} xs={12} sm={6} md={6} >
                            <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item mb='10px' xs={12}>
                                    <Typography variant='t20_600'>BCP-PITSTOP</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='t16_400_1'>S2V HOSPITALITY</Typography>
                                </Grid>
                                <Grid xs={12} item paddingTop='20px'>
                                    <Typography variant='t16_400_1' color='common.t'>A-13, 3rd Phase, SIDCO Industrial Estate, Guindy, Chennai, Tamil Nadu India- 600032</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12} sm={6} md={6}>
                            <Box display='flex' height='100%' width='100%' flexDirection='column' >

                                <Box mt='auto' >
                                <Box pt='10px' display='flex' alignItems='center'>
                                    <DraftsOutlined color='primary' />

                                    <Typography variant='t16_400' paddingLeft='10px'>shanthosh.sivagnanam@gmail.com</Typography>
                                </Box>
                                <Box pt='10px' display='flex' alignItems='center' >
                                    <PhoneInTalkOutlined color='primary' />

                                    <Typography variant='t16_400' display={{xs: 'none', md: 'block'}} paddingLeft='10px'>Contact Info: </Typography>

                                    <Typography variant='t16_600' paddingLeft='10px'> +919150928222 </Typography>
                                </Box>
                                </Box>
                                
                            </Box>


                            {/* <Grid item lg={12} xs={12} md={12} sm={12} display='flex' paddingTop='10px' >

                                    <WatchLaterOutlined color='primary' />

                                    <Typography variant='t18_400' paddingLeft='20px'>Shift Duty Manager (24x7) :</Typography>

                                    <Typography variant='t18_600' paddingLeft='10px'> +91944567762</Typography>

                                </Grid> */}
                    </Grid>
                </Grid>
            </Card>
        </Box>
        </div >
    )
}
