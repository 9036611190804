import { GET_USER_HISTORY_WATCHER, USER_HISTORY_LIST, SPECIAL_HISTORY_LIST, GET_SPECIAL_HISTORY_WATCHER, GET_ORDER_HISTORY_WATCHER, GET_GUEST_ORDER_HISTORY_WATCHER, ORDER_HISTORY_LIST, GET_DINNER_DAY_BY_DAY_ORDER_WATCHER, GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER, GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER, GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER, GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER, DAY_BY_DAY_ORDER_LIST, LUNCH_ORDER_LIST, DINNER_ORDER_LIST, SUPPER_ORDER_LIST, SNACKS_ORDER_LIST, BREAKFAST_ORDER_LIST, GET_PUSH_NOTIFICATION_WATCHER, PUSH_NOTIFICATION_LIST, CREATE_PUSH_NOTIFICATION_WATCHER, ORDER_TRACKING_LIST, GET_ORDER_TRACKING_WATCHER, CONTRACTOR_SWIPE_HISTORY_LIST, GUEST_SWIPE_HISTORY_LIST, GET_CONTRACTOR_SWIPE_HISTORY_WATCHER, GET_GUEST_SWIPE_HISTORY_WATCHER, DELIVERY_HISTORY_LIST, GUEST_DELIVERY_HISTORY_LIST, GET_DELIVERY_HISTORY_WATCHER, GET_GUEST_DELIVERY_HISTORY_WATCHER, GET_RATING_FEEDBACK_WATCHER,  GET_ACTIVITY_WATCHER, RATING_FEEDBACK_LIST, ACTIVITY_LIST, GET_SPECIAL_ORDER_TRACKING_WATCHER, SPECIAL_ORDER_LIST, GET_CONTRACTOR_INVOICE_WATCHER, CONTRACTOR_INVOICE_LIST, GET_USER_HISTORY_VIEW_WATCHER, GET_SPECIAL_HISTORY_VIEW_WATCHER, USER_HISTORY_VIEW_LIST, SPECIAL_HISTORY_VIEW_LIST, GET_SALE_REPORT_WATCHER, SALE_REPORT_LIST, SALE_MENU_LIST, GET_SALE_MENU_WATCHER, GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER, GET_ORDER_TRACKING_VIEW_WATCHER, SPECIAL_ORDER_VIEW_LIST, ORDER_TRACKING_VIEW_LIST, UPDATE_FEEDBACK_WATCHER, GUEST_ORDER_HISTORY_LIST, GET_KIOSK_USER_HISTORY_WATCHER, GET_KIOSK_USER_HISTORY_VIEW_WATCHER, KIOSK_USER_HISTORY_LIST, KIOSK_USER_HISTORY_VIEW_LIST, KIOSK_ORDER_TRACKING_LIST, GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER, GET_KIOSK_ORDER_TRACKING_WATCHER } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getUserHistoryWatcher(data) {
    return { type: GET_USER_HISTORY_WATCHER, payload: data };
}

export function getKioskUserHistoryWatcher(data) {
    return { type: GET_KIOSK_USER_HISTORY_WATCHER, payload: data };
}

export function getUserHistoryViewWatcher(data) {
    return { type: GET_USER_HISTORY_VIEW_WATCHER, payload: data };
}

export function getKioskUserHistoryViewWatcher(data) {
    return { type: GET_KIOSK_USER_HISTORY_VIEW_WATCHER, payload: data };
}

export function getSpecialHistoryViewWatcher(data) {
    return { type: GET_SPECIAL_HISTORY_VIEW_WATCHER, payload: data };
}

export function getSpecialOrderTrackingViewWatcher(data) {
    return { type: GET_SPECIAL_ORDER_TRACKING_VIEW_WATCHER, payload: data };
}

export function getOrderTrackingViewWatcher(data, setOpen) {
    return { type: GET_ORDER_TRACKING_VIEW_WATCHER, payload: data, setOpen };
}

export function getOrderTrackingWatcher(data) {
    return { type: GET_ORDER_TRACKING_WATCHER, payload: data };
}

export function getKioskOrderTrackingViewWatcher(data, setOpen) {
    return { type: GET_KIOSK_ORDER_TRACKING_VIEW_WATCHER, payload: data, setOpen };
}

export function getKioskOrderTrackingWatcher(data) {
    return { type: GET_KIOSK_ORDER_TRACKING_WATCHER, payload: data };
}

export function getContractorSwipeHistoryWatcher(data) {
    return { type: GET_CONTRACTOR_SWIPE_HISTORY_WATCHER, payload: data };
}

export function getGuestSwipeHistoryWatcher(data) {
    return { type: GET_GUEST_SWIPE_HISTORY_WATCHER, payload: data };
}

export function getDeliveryHistoryWatcher(data) {
    return { type: GET_DELIVERY_HISTORY_WATCHER, payload: data };
}

export function getGuestDeliveryHistoryWatcher(data) {
    return { type: GET_GUEST_DELIVERY_HISTORY_WATCHER, payload: data };
}

// export function getRatingWatcher(data) {
//     return { type: GET_RATING_WATCHER, payload: data };
// }

export function getRatingFeedbackWatcher(data) {
    return { type: GET_RATING_FEEDBACK_WATCHER, payload: data };
}

export function getActivityWatcher(data) {
    return { type: GET_ACTIVITY_WATCHER, payload: data };
}

export function getSpecialHistoryWatcher(data) {
    return { type: GET_SPECIAL_HISTORY_WATCHER, payload: data };
}

export function getOrderHistoryWatcher(data) {
    return { type: GET_ORDER_HISTORY_WATCHER, payload: data };
}

export function getGuestOrderHistoryWatcher(data) {
    return { type: GET_GUEST_ORDER_HISTORY_WATCHER, payload: data };
}

export function getBreakfastDayByDayOrderWatcher(data) {
    return { type: GET_BREAKFAST_DAY_BY_DAY_ORDER_WATCHER, payload: data };
}

export function getLunchDayByDayOrderWatcher(data) {
    return { type: GET_LUNCH_DAY_BY_DAY_ORDER_WATCHER, payload: data };
}

export function getDinnerByDayOrderWatcher(data) {
    return { type: GET_DINNER_DAY_BY_DAY_ORDER_WATCHER, payload: data };
}

export function getSnacksByDayOrderWatcher(data) {
    return { type: GET_SNACKS_DAY_BY_DAY_ORDER_WATCHER, payload: data };
}

export function getSupperByDayOrderWatcher(data) {
    return { type: GET_SUPPER_DAY_BY_DAY_ORDER_WATCHER, payload: data };
}

export function getPushNotificationWatcher(data) {
    return { type: GET_PUSH_NOTIFICATION_WATCHER, payload: data };
}

export function getSpecialOrderTrackingWatcher(data) {
    return { type: GET_SPECIAL_ORDER_TRACKING_WATCHER, payload: data };
}

export function createPushNotificationWatcher(data, setOpen) {
    return { type: CREATE_PUSH_NOTIFICATION_WATCHER, payload: data, setOpen };
}

export function getContractorInvoiceWatcher(data) {
    return { type: GET_CONTRACTOR_INVOICE_WATCHER, payload: data }
}

export function getSaleReportWatcher(authParams) {
    return { type: GET_SALE_REPORT_WATCHER, payload: authParams }
}

export function getSaleMenuWatcher(authParams) {
    return { type: GET_SALE_MENU_WATCHER, payload: authParams }
}

export function updateFeedbackWatcher(id, data) {
    return { type: UPDATE_FEEDBACK_WATCHER, payload: data, id }
}

// Redux state changing actionCreators

export function userHistoryList(data) {
    return { type: USER_HISTORY_LIST, payload: data };
}

export function kioskUserHistoryList(data) {
    return { type: KIOSK_USER_HISTORY_LIST, payload: data };
}

export function orderTrackingList(data) {
    return { type: ORDER_TRACKING_LIST, payload: data };
}

export function kioskOrderTrackingList(data) {
    return { type: KIOSK_ORDER_TRACKING_LIST, payload: data };
}

export function contractorSwipeHistoryList(data) {
    return { type: CONTRACTOR_SWIPE_HISTORY_LIST, payload: data };
}

export function guestSwipeHistoryList(data) {
    return { type: GUEST_SWIPE_HISTORY_LIST, payload: data };
}

export function deliveryHistoryList(data) {
    return { type: DELIVERY_HISTORY_LIST, payload: data };
}

export function guestDeliveryHistoryList(data) {
    return { type: GUEST_DELIVERY_HISTORY_LIST, payload: data };
}

export function ratingFeedbackList(data) {
    return { type: RATING_FEEDBACK_LIST, payload: data }
}

export function activityList(data) {
    return { type: ACTIVITY_LIST, payload: data }
}

export function specialHistoryList(data) {
    return { type: SPECIAL_HISTORY_LIST, payload: data };
}

export function orderHistoryList(data) {
    return { type: ORDER_HISTORY_LIST, payload: data };
}

export function guestOrderHistoryList(data) {
    return { type: GUEST_ORDER_HISTORY_LIST, payload: data };
}

export function dayByDayOrderList(data) {
    return { type: DAY_BY_DAY_ORDER_LIST, payload: data };
}

export function breakfastOrderList(data) {
    return { type: BREAKFAST_ORDER_LIST, payload: data };
}

export function lunchOrderList(data) {
    return { type: LUNCH_ORDER_LIST, payload: data };
}

export function dinnerOrderList(data) {
    return { type: DINNER_ORDER_LIST, payload: data };
}

export function supperOrderList(data) {
    return { type: SUPPER_ORDER_LIST, payload: data };
}

export function snacksOrderList(data) {
    return { type: SNACKS_ORDER_LIST, payload: data };
}

export function pushNotificationList(data) {
    return { type: PUSH_NOTIFICATION_LIST, payload: data };
}

export function specialOrderList(data) {
    return { type: SPECIAL_ORDER_LIST, payload: data };
}

export function contractorInvoiceList(data) {
    return { type: CONTRACTOR_INVOICE_LIST, payload: data };
}

export function saleReportList(data) {
    return { type: SALE_REPORT_LIST, payload: data };
}

export function saleMenuList(data) {
    return { type: SALE_MENU_LIST, payload: data };
}

export function userHistoryViewList(data) {
    return { type: USER_HISTORY_VIEW_LIST, payload: data };
} 

export function kioskUserHistoryViewList(data) {
    return { type: KIOSK_USER_HISTORY_VIEW_LIST, payload: data };
}

export function specialHistoryViewList(data) {
    return { type: SPECIAL_HISTORY_VIEW_LIST, payload: data };
}

export function orderTrackingViewList(data) {
    return { type: ORDER_TRACKING_VIEW_LIST, payload: data };
}

export function specialOrderViewList(data) {
    return { type: SPECIAL_ORDER_VIEW_LIST, payload: data };
}
