import { USER_HISTORY_LIST, KIOSK_USER_HISTORY_LIST, SPECIAL_HISTORY_LIST, EMPLOYEE_CONTRACTOR_LIST, ORDER_HISTORY_LIST, DAY_BY_DAY_ORDER_LIST, LUNCH_ORDER_LIST, DINNER_ORDER_LIST, SUPPER_ORDER_LIST, SNACKS_ORDER_LIST, BREAKFAST_ORDER_LIST, PUSH_NOTIFICATION_LIST, ORDER_TRACKING_LIST, CONTRACTOR_SWIPE_HISTORY_LIST, GUEST_SWIPE_HISTORY_LIST, DELIVERY_HISTORY_LIST, GUEST_DELIVERY_HISTORY_LIST, RATING_FEEDBACK_LIST, ACTIVITY_LIST, SPECIAL_ORDER_LIST, CONTRACTOR_INVOICE_LIST, SPECIAL_HISTORY_VIEW_LIST, USER_HISTORY_VIEW_LIST, KIOSK_USER_HISTORY_VIEW_LIST, SALE_REPORT_LIST, SALE_MENU_LIST, ORDER_TRACKING_VIEW_LIST,GUEST_ORDER_HISTORY_LIST, KIOSK_ORDER_TRACKING_LIST, KIOSK_ORDER_TRACKING_VIEW_LIST } from "../../actionTypes/admin";

const intialState = {
  UserHistoryList: [],
  KioskUserHistoryList: [],
  UserHistoryViewList: [],
  KioskUserHistoryViewList: [],
  SpecialHistoryViewList: [],
  SpecialHistoryList: [],
  OrderHistoryList: [],
  GuestOrderHistoryList: [],
  EmployeeContractorList: [],
  OrderTrackingList: [],
  KioskOrderTrackingList: [],
  ContractorSwipeHistoryList: [],
  GuestSwipeHistoryList: [],
  DeliveryHistoryList: [],
  GuestDeliveryHistoryList: [],
  RatingFeedbackList: [],
  ActivityList: [],
  DayByDayOrderList: [],
  BreakfastList: [],
  LunchList: [],
  DinnerList: [],
  SnacksList: [],
  SupperList: [],
  PushNotificationList: [],
  SpecialOrderList: [],
  ContractorInvoiceList: [],
  SaleReportList: [],
  SaleMenuList: [],
  orderTrackingViewList: {},
  KioskOrderTrackingViewList: {},
};

export default function ReportsReducers(state = intialState, action) {
  switch (action.type) {

    case USER_HISTORY_LIST:
      return { ...state, UserHistoryList: action.payload };

    case KIOSK_USER_HISTORY_LIST:
      return { ...state, KioskUserHistoryList: action.payload };

    case USER_HISTORY_VIEW_LIST:
      return { ...state, UserHistoryViewList: action.payload };

    case KIOSK_USER_HISTORY_VIEW_LIST:
      return { ...state, KioskUserHistoryViewList: action.payload };

    case EMPLOYEE_CONTRACTOR_LIST:
      return { ...state, EmployeeContractorList: action.payload };

    case ORDER_TRACKING_LIST:
      return { ...state, OrderTrackingList: action.payload };

    case KIOSK_ORDER_TRACKING_LIST:
      return { ...state, KioskOrderTrackingList: action.payload };

    case CONTRACTOR_SWIPE_HISTORY_LIST:
      return { ...state, ContractorSwipeHistoryList: action.payload };

    case GUEST_SWIPE_HISTORY_LIST:
      return { ...state, GuestSwipeHistoryList: action.payload };

    case DELIVERY_HISTORY_LIST:
      return { ...state, DeliveryHistoryList: action.payload };

    case GUEST_DELIVERY_HISTORY_LIST:
      return { ...state, GuestDeliveryHistoryList: action.payload };

    case RATING_FEEDBACK_LIST:
      return { ...state, RatingFeedbackList: action.payload }

    case ACTIVITY_LIST:
      return { ...state, ActivityList: action.payload }

    case SPECIAL_HISTORY_LIST:
      return { ...state, SpecialHistoryList: action.payload };

    case SPECIAL_HISTORY_VIEW_LIST:
      return { ...state, SpecialHistoryViewList: action.payload };

    case ORDER_HISTORY_LIST:
      return { ...state, OrderHistoryList: action.payload };

    case GUEST_ORDER_HISTORY_LIST:
      return { ...state, GuestOrderHistoryList: action.payload };

    case DAY_BY_DAY_ORDER_LIST:
      return { ...state, DayByDayOrderList: action.payload };

    case BREAKFAST_ORDER_LIST:
      return { ...state, BreakfastList: action.payload };

    case LUNCH_ORDER_LIST:
      return { ...state, LunchList: action.payload };

    case DINNER_ORDER_LIST:
      return { ...state, DinnerList: action.payload };

    case SNACKS_ORDER_LIST:
      return { ...state, SnacksList: action.payload };

    case SUPPER_ORDER_LIST:
      return { ...state, SupperList: action.payload };

    case PUSH_NOTIFICATION_LIST:
      return { ...state, PushNotificationList: action.payload };

    case SPECIAL_ORDER_LIST:
      return { ...state, SpecialOrderList: action.payload };

    case CONTRACTOR_INVOICE_LIST:
      return { ...state, ContractorInvoiceList: action.payload };

    case SALE_REPORT_LIST:
      return { ...state, SaleReportList: action.payload };

    case SALE_MENU_LIST:
      return { ...state, SaleMenuList: action.payload };

    case ORDER_TRACKING_VIEW_LIST:
      return { ...state, orderTrackingViewList: action.payload }

    case KIOSK_ORDER_TRACKING_VIEW_LIST:
      return { ...state, KioskOrderTrackingViewList: action.payload }

    default:
      return state;
  }
}
