import axios from 'axios';

let baseURL = 'http://localhost:4000'
let wsUrl = 'ws://localhost:4000'

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://server.bcp-pitstop.com'
  wsUrl = 'wss://server.bcp-pitstop.com'
}

const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    'Content-type': 'application/json',
  }
});

export const base_url = baseURL;
export const ws_url = wsUrl;
export default instance;
