import React, { useEffect, useRef } from 'react'
import { Typography, Card, Grid, Box, Autocomplete, Stack } from "@mui/material";
import Breadcrumbs from "../../../components/breadcrumbs";
// import ContractorBookTable from '../../../components/admin/kitchen/contractorBook/contractorTable';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssTextField from '../../../components/overrides/longSearchField';
import BlackButton from '../../../components/overrides/blackButton'
import { createContractBookWatcher, getContractorNameListWatcher, cancelContractBookWatcher, cnsListWatcher, selectAllMealWatcher } from '../../../redux/actionCreators/admin/contractorBook';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConvertedDate, timeVal } from '../../../utils/common';
import _ from 'lodash'
import CancelDialog from '../../../components/admin/kitchen/contractorBook/cancelDialog';
import { updateAlert } from '../../../redux/actionCreators/alert';
import { getDropDownWatcher } from "../../../redux/actionCreators/admin/employeeManagement";
import { selectGuestCountWatcher } from '../../../redux/actionCreators/admin/guestBook';

export default function ContractorBook() {

    const dispatch = useDispatch();
    const { ContractorBookReducers: { contractorNameList, menuIdList }, ProfileReducers: { loginState }, EmployeeReducers: { cafeteriaName }, GuestBookReducers: { guestUserCount }, } = useSelector((state) => state)

    const nUserID = loginState[0]?.nID
    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();

    const gCount = guestUserCount[0]?.GuserCount ? guestUserCount[0]?.GuserCount : 0

    var today = new Date();
    var bookingTime = timeVal(today)
    const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    // const [dateChange, setDateChange] = useState(false)

    const custValidation = (Yup) => {
        if (cafeteriaList.length) {
            const cust = {}
            const meals = ['Breakfast', 'Lunch', 'Snacks', 'Dinner', 'Supper', 'Snacks(Take away)']
            cafeteriaList.forEach(m => {
                const diff = cafeteriaList.filter(f => f.id !== m.id)

                meals.forEach(d => {
                    const getBal = (values) => {
                        const bal = diff.reduce((a, b) => {
                            return a + (values[`${d}-${b.id}`] || 0)
                        }, 0)
                        return bal
                    }

                    cust[`${d}-${m.id}`] = Yup.number().test("Invalid count", "Invalid count", (data = 0, mm) => {
                        if ((data + getBal(mm.parent)) > gCount) {
                            return false
                        }
                        return true
                    })
                })

            })
            return cust
        }
        return {}
    }

    const Schema = Yup.object().shape({
        contractorID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select contractor'),
        ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            contractorID: '',
            orderDate: '',
            supperDate: ''
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { contractorID, orderdate, menuID, orderDate, supperDate, ...rest } = { ...formik.values }

            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })
            const data = {
                "ACTION": "InsertOrderBooking",
                "nContractorID": `${contractorID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId(),
                orderDate: orderDate
            }
            if (mealArr.length) {
                dispatch(createContractBookWatcher(data))
            }
        }
    });

    const initsform = () => {
        dispatch(getDropDownWatcher());
        dispatch(getContractorNameListWatcher())
        dispatch(selectAllMealWatcher({ "ACTION": "SelectAllMeal" }))
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        setFieldValue("orderDate", tomorrow)
        setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, []);

    // useEffect(() => {
    //     dispatch(getDropDownWatcher());
    //     dispatch(getContractorNameListWatcher())
    //     dispatch(selectAllMealWatcher({ "ACTION": "SelectAllMeal" }))
    //     let tomorrow = new Date();
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     let dayAfterTomorrow = new Date();
    //     dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    //     setFieldValue("orderDate", tomorrow)
    //     setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
    // }, [])

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    const inits = () => {
        if (values.contractorID) {
            let data = {
                "ACTION": "Cns",
                "nID": `${values.contractorID}`
            }
            let dat = {
                "ACTION": "CuserCount",
                "nID": `${values.contractorID}`
            }
            dispatch(cnsListWatcher(data))
            dispatch(selectGuestCountWatcher(dat))
        }
    };

    tempinits.current = inits;

    useEffect(() => {
        tempinits.current();
    }, [values.contractorID]);


    // useEffect(() => {
    //     if (values.contractorID) {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.contractorID}`
    //         }
    //         dispatch(cnsListWatcher(data))
    //     }
    // }, [values.contractorID])

    const getBookingId = () => {
        const get = menuIdList.find(d => d.nid) || {}
        return get.nid
    }

    const init = () => {
        if (menuIdList.length && values.contractorID) {
            menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }
    };

    tempinit.current = init;

    useEffect(() => {
        tempinit.current();
    }, [menuIdList]);

    // useEffect(() => {
    //     if (menuIdList.length && values.contractorID) {
    //         menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdList])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }

        if (values.contractorID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nContractorId: values.contractorID

            }
            dispatch(cancelContractBookWatcher(id, data))
        }
    }
    // const selectObj = { ID: 0, Name: "-SELECT-" }

    return (
        <div key='mainDiv'>
            <Box p='30px'>
                <Breadcrumbs content={["Kitchen", "Contractor Book"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Contractor Book </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                        <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={_.uniqBy(contractorNameList, data => data.Name)}
                                name="contractorID"
                                autoHighlight
                                // defaultValue={values.contractorID === "0" ? selectObj : selectObj}
                                getOptionLabel={(option) => option.Name}
                                onChange={(event, value) => {
                                    setFieldValue("contractorID", value?.ID);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '16px'
                                        },
                                        '& label': {
                                            top: -2
                                        }
                                    }}
                                    name="contractorID"
                                    label="Select Contractor"
                                    required={true}
                                    error={Boolean(touched.contractorID && errors.contractorID)}
                                    helperText={touched.contractorID && errors.contractorID}
                                />}
                            />
                        </Grid>

                        <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label='Order Date'
                                    inputFormat="dd/MM/yyyy"
                                    // inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <CssTextField {...params}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '48px'
                                            }
                                        }}
                                        fullWidth />}
                                    name="orderDate"
                                    // {...getFieldProps("orderDate")}
                                    value={values.orderDate}
                                    // error={Boolean(touched.orderDate && errors.orderDate)}
                                    // helperText={dateChange ? "Order date cannot be changed" : ""}
                                    onChange={() => {
                                        // setFieldValue("orderDate", newValue);
                                        // setDateChange(true)

                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Card>
                <br />
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px', paddingTop: 50 }}>
                    {cafeteriaList?.map((m, i) => (
                        //<>
                        <Grid key={i} container spacing={2} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '15px', pt: 0 }}>

                            <Box bgcolor='primary.main' height='50px' width='80px' m='auto 10px 0 20px' borderRadius='4px' display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='t16_400' color='common.white'>{`${m.name}`}</Typography></Box>
                            <Grid item
                                lg={1.8}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Breakfast </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Breakfast'
                                    name={`Breakfast-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Breakfast-${m.id}`)}
                                    error={Boolean(touched[`Breakfast-${m.id}`] && errors[`Breakfast-${m.id}`])}

                                />
                            </Grid>

                            <Grid item
                                lg={1.8}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Lunch </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Lunch'
                                    name={`Lunch-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Lunch-${m.id}`)}
                                    error={Boolean(touched[`Lunch-${m.id}`] && errors[`Lunch-${m.id}`])}
                                />
                            </Grid>

                            <Grid item
                                lg={1.8}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Snacks </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Snacks'
                                    name={`Snacks-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Snacks-${m.id}`)}
                                    error={Boolean(touched[`Snacks-${m.id}`] && errors[`Snacks-${m.id}`])}
                                />
                            </Grid>

                            <Grid item
                                lg={1.8}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Dinner </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Dinner'
                                    name={`Dinner-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Dinner-${m.id}`)}
                                    error={Boolean(touched[`Dinner-${m.id}`] && errors[`Dinner-${m.id}`])}
                                />
                            </Grid>

                            <Grid item
                                lg={1.8}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Supper </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Supper'
                                    name={`Supper-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Supper-${m.id}`)}
                                    error={Boolean(touched[`Supper-${m.id}`] && errors[`Supper-${m.id}`])}
                                />
                            </Grid>

                            <Grid item
                                lg={1.85}
                                xs={6}
                                md={3}
                            >
                                <Typography variant='t14_600'> {'Snacks(Take away)'} </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Snacks(Take away)'
                                    name={`Snacks(Take away)-${m.id}`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Snacks(Take away)-${m.id}`)}
                                    error={Boolean(touched[`Snacks(Take away)-${m.id}`] && errors[`Snacks(Take away)-${m.id}`])}
                                />
                            </Grid>
                        </Grid>
                        //</>
                    ))}
                    <Stack color='common.sred' spacing={0.5} mt='20px' ml='20px' >
                        <Typography variant='t14_600'>Note:</Typography>
                        {bookingTime ?
                            <>
                                <Typography variant='t14_400'>{`Please note the supper booking is applicable for ${values.supperDate}.`}</Typography>
                                <Typography variant='t14_400'>{`Maximum no of allowed quantity for booking in each session is ${values.contractorID ? gCount : 0}.`}</Typography>
                            </>
                            :
                            <Typography variant='t14_400'>{`Please note the order booking is not available after 7PM `}</Typography>
                        }
                    </Stack>
                </Card>

                {/* <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ paddingTop: '50px' }}
                >
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                        }} variant='contained' color='inherit'
                            onClick={handleCancel}>
                            <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack'
                        }} variant='contained' color='primary' onClick={handleSubmit}>
                            <Typography variant='t14_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid> */}

                <Grid container
                    item={true}
                    display='flex'
                    justifyContent='flex-end'
                    flexDirection='row'
                    paddingTop='30px'
                    spacing={2}
                >
                    <Grid
                        item={true}
                    >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid
                        item={true}
                    >
                        <BlackButton
                            style={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            disabled={bookingTime ? false : true}
                            type='save'
                            onClick={handleSubmit}
                        >
                            <Typography color='inherit' variant='t16_400'>Save</Typography>
                        </BlackButton>
                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}
